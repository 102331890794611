*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 7.5rem;
}

body{
	background: #F8EBD5;
	font-family: Didot, "Playfair Display", 'Times New Roman', Times, serif;
	padding-top: 70px;
}

/* NAVBAR START */
.navbar {
    font-family: Didot, "Playfair Display", 'Times New Roman', Times, serif;
	box-shadow: 0 1px 7px #343a40ee;
}

.navbar-brand {
	font-size: 1.5rem;
}

.nav-link {
	font-size: 1.3rem;
}

.nav-item .btn {
	font-size: 1.3rem;
}

.dropdown-item {
	font-size: 1.15rem;
}
/* NAVBAR END */

/* COUNTER START */
.content-container {
	display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
}

.tasbeeh-container {
	flex-basis: 600px;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 13px;
    padding-bottom: 20px;
    background-color: white;
}

.how-container {
	flex-basis: 1240px;
	font-family: Calibri, 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
}

.count {
	user-select: none;
	text-align: center;
	font-size: 15rem;
}

/* COUNTER END */

/* CUSTOM FONTS START */

@font-face {
	font-family: Zuhair-regular-display;
	src: url(./fonts/ZuhairAlbaziNaskhDisplay-Regular.otf) format("opentype");
}

.arabic-display {
	font-family: 'Zuhair-regular-display';
}

@font-face {
	font-family: Zuhair-bold-display;
	src: url(./fonts/ZuhairAlbaziNaskhDisplay-Bold.otf) format("opentype");
}

.arabic-bold-display {
	font-family: 'Zuhair-bold-display';
}

@font-face {
	font-family: Zuhair-regular-text;
	src: url(./fonts/ZuhairAlbaziNaskhText-Regular.otf) format("opentype");
}

.arabic-text {
	font-family: 'Zuhair-regular-text';
}

@font-face {
	font-family: Zuhair-bold-text;
	src: url(./fonts/ZuhairAlbaziNaskhText-Bold.otf) format("opentype");
}

.arabic-bold-text {
	font-family: 'Zuhair-bold-text';
}

/* CUSTOM FONTS END */

/* MEDIA QUERIES */
@media only screen and (max-device-width: 480px) {
	.juz {
		 margin: 0;
	   position: absolute;
	   top: 52%;
	   left: 50%;
	   transform: translate(-50%, -50%);
	 }
	 .carousel {
	   width:370px;
	   height:548px;
	 }
	 #juz-select .btn {
	 font-size: 0.85rem;
	 }
	 .modal-dialog {
		 margin: auto;
	 }
	 .count {
		font-size: 11rem;
	}
 }

@media only screen and (min-device-width: 481px) and (max-width: 576px) {
	html {
	  scroll-padding-top: 13rem;
	}
	.carousel {
	  width:370px;
	  height:548px;
	}
	#juz-select .btn {
    font-size: 1rem;
	}
	.modal-dialog {
		margin: auto;
	}
	.count {
		font-size: 12rem;
	}
}

@media only screen and (min-device-width: 577px) and (max-device-width: 992px) {
   .juz {
		margin: 0;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	}
	.carousel {
	  width:700px;
	  height:1036px;
	}
	.modal-dialog {
		margin: auto;
	}
	.count {
		font-size: 13.5rem;
	}
}
/* MEDIA QUERIES END */